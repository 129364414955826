import {
  Component,
  Inject,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';

import { take, tap } from 'rxjs/operators';

import { AuthService, UserService } from '@ds/api-services';
import { DS_MODAL_DATA, ModalRef, ModalService } from '@ds/core';
import { Profile, User } from '@ds/interfaces';

import { ContentBlockFileStackService } from '../content-block-form/content-block-form-upload.component';

@Component({
  selector: 'ds-cb-edit-bio-details',
  template: `
    <h3>Edit Bio</h3>
    <textarea [(ngModel)]="bio" class="form-control"></textarea>
    <div class="actions">
      <button class="ds-button alt" (click)="close()">Cancel</button>
      <button class="ds-button" (click)="save()">Save</button>
    </div>
  `,
  styles: [
    `
      :host {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 600px;
      }
      textarea {
        width: 100%;
        height: 200px;
      }
      .actions {
        display: flex;
        justify-content: space-between;
      }
    `,
  ],
})
export class EditUserProfileComponent implements OnInit {
  user: User;

  bio = '';

  constructor(
    private modalRef: ModalRef<string>,
    @Inject(DS_MODAL_DATA)
    public data: {
      user?: User;
    }
  ) {
    this.user = data.user;
    this.bio = this.user.props?.bio || '';
  }

  ngOnInit() {}

  close() {
    this.modalRef.close(null);
  }

  save() {
    this.modalRef.close(this.bio);
  }
}

@Component({
  selector: 'ds-cb-staff-detail-modal',
  template: `
    <div class="staff-detail-container ds-row" *ngIf="user; else loadingTpl">
      <div class="staff-detail-photo ds-col">
        <ds-cb-image
          [imageHandle]="user.photoHandle"
          size="large"
        ></ds-cb-image>
        <div
          style="display: flex; justify-content: space-evenly"
          *ngIf="allowPhotoEditing"
        >
          <button
            (click)="removePhoto(user)"
            class="ds-button alt"
            *ngIf="isOwner && user.photoHandle"
          >
            Remove Photo
          </button>
          <button class="ds-button" (click)="changePhoto(user)" *ngIf="isOwner">
            Replace Photo
          </button>
        </div>
      </div>
      <div class="staff-detail-info ds-fluid-text ds-col">
        <h2 class="ds-fluid-text-lg">{{ user.displayName }}</h2>
        <div *ngIf="user.title && user.title.length">{{ user.title }}</div>
        <div>{{ user.location.name }}</div>
        <table class="ds-table">
          <tr>
            <th>Email Address</th>
            <td>
              <a [href]="getUserEmailAddressLink(user)">{{
                user.emailAddress
              }}</a>
            </td>
          </tr>
          <tr *ngFor="let ph of user.phoneNumbers">
            <th class="ph-type">{{ ph.type }}</th>
            <td>
              {{ ph.number }} <span class="extension">{{ ph.extension }}</span>
            </td>
          </tr>
        </table>
        <div class="bio-container">
          <p
            class="bio"
            *ngIf="user.props && user.props.bio"
            [innerText]="user.props.bio"
          ></p>
        </div>
        <button class="ds-button" (click)="openEditBio()" *ngIf="isOwner">
          Edit Bio
        </button>
      </div>
    </div>
    <ng-template #loadingTpl>
      <div>Loading</div>
    </ng-template>
  `,
  styles: [
    `
      :host {
        display: block;
        max-width: 95vw;
        width: 800px;
      }
      .staff-detail-container {
      }
      .staff-detail-container > div {
      }
      .staff-detail-photo {
        text-align: center;
        max-width: 250px;
      }
      .staff-detail-info {
        display: flex;
        flex-direction: column;
      }
      .staff-detail-info > * {
        margin-bottom: 10px;
      }
      th {
        white-space: nowrap;
        text-align: left;
      }
      .ph-type {
        text-transform: capitalize;
      }
      .bio-container {
        flex: 1;
      }
      .bio {
        white-space: pre-wrap;
        border: 2px solid rgba(0, 0, 0, 0.12);
        padding: 10px;
        border-radius: 11px;
      }
    `,
  ],
})
export class ContentBlockStaffDetailModalComponent implements OnInit {
  userId: number;

  user: User;

  currentUser: Profile;

  isOwner = false;

  allowPhotoEditing = true;

  @ViewChild('editBio') editBio: TemplateRef<any>;

  constructor(
    private uploadService: ContentBlockFileStackService,
    private authService: AuthService,
    private userService: UserService,
    private modalRef: ModalRef<string>,
    private modal: ModalService,
    @Inject(DS_MODAL_DATA)
    public data: {
      user?: User;
      userId?: number;
    }
  ) {
    if (data.user && data.userId) {
      console.warn('no user id found');
      return;
    }
    this.currentUser = this.authService.session.profile;
    this.userId = data.userId || data.user.id;

    this.fetchUser();
  }

  fetchUser() {
    this.userService
      .findOne(this.userId)
      .pipe(
        tap((user) => (this.isOwner = user.id === this.currentUser.id)),
        take(1)
      )
      .subscribe((val) => (this.user = val));
  }

  getUserEmailAddressLink(user: User) {
    return `mailto:${user.emailAddress}`;
  }

  ngOnInit(): void {}

  changePhoto() {
    this.uploadService
      .uploadFile({ accept: ['image/*'] })
      .subscribe((files) => {
        this.userService.updateIcon(this.user, files[0]).subscribe((user) => {
          this.fetchUser();
        });
      });
  }

  removePhoto() {
    this.userService.destroyIcon(this.user).subscribe((user) => {
      this.fetchUser();
    });
  }

  openEditBio() {
    this.modal
      .open(EditUserProfileComponent, { user: this.user })
      .onClose.subscribe((result) => {
        console.log(result);
        if (!result) return;
        this.userService
          .update({ ...this.user, props: { ...this.user.props, bio: result } })
          .subscribe((user) => {
            this.fetchUser();
          });
      });
  }
}
