import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  Route,
  RouterModule,
  Routes,
  UrlMatchResult,
  UrlSegment,
  UrlSegmentGroup,
} from '@angular/router';
import { CoreModule } from '@ds/core';
import { ConfigOption, FormlyModule } from '@ngx-formly/core';
import { InlineSVGModule } from 'ng-inline-svg';
import { ContentBlockBlogActiveFiltersComponent } from './components/content-block-blog/content-block-blog-active-filters.component';
import { ContentBlockBlogBrowseComponent } from './components/content-block-blog/content-block-blog-browse.component';
import { ContentBlockBlogCardComponent } from './components/content-block-blog/content-block-blog-card.component';
import { ContentBlockBlogDatesModalComponent } from './components/content-block-blog/content-block-blog-dates-modal.component';
import { ContentBlockBlogDatesComponent } from './components/content-block-blog/content-block-blog-dates.component';
import { ContentBlockBlogDetailComponent } from './components/content-block-blog/content-block-blog-detail.component';
import { ContentBlockBlogTagsModalComponent } from './components/content-block-blog/content-block-blog-tags-modal.component';
import { ContentBlockBlogTagsComponent } from './components/content-block-blog/content-block-blog-tags.component';
import { ContentBlockBlogComponent } from './components/content-block-blog/content-block-blog.component';
import { ContentBlockCardComponent } from './components/content-block-card/content-block-card.component';
import { ContentBlockColumnComponent } from './components/content-block-column/content-block-column.component';
import { ContentBlockContactCardComponent } from './components/content-block-contact-card/content-block-contact-card.component';
import { ContentBlockContactFormComponent } from './components/content-block-contact-card/content-block-contact-form.component';
import { ContentBlockWebsiteContactsComponent } from './components/content-block-contact-card/content-block-website-contacts.component';
import { ContentBlockFinancingCalculatorComponent } from './components/content-block-financing-calculator/content-block-financing-calculator.component';
import { ContentBlockFolderBrowserComponent } from './components/content-block-folder-browser/content-block-folder-browser.component';
import { ContentBlockFooterComponent } from './components/content-block-footer/content-block-footer.component';
import { ContentBlockFileUploadComponent } from './components/content-block-form/content-block-form-upload.component';
import { ContentBlockFormComponent } from './components/content-block-form/content-block-form.component';
import { ContentBlockGalleryComponent } from './components/content-block-gallery/content-block-gallery.component';
import { ContentBlockHeaderComponent } from './components/content-block-header/content-block-header.component';
import { ContentBlockHTMLComponent } from './components/content-block-html/content-block-html.component';
import { ContentBlockIFrameComponent } from './components/content-block-iframe/content-block-iframe.component';
import { ContentBlockImageComponent } from './components/content-block-image/content-block-image.component';
import { ContentBlockInventoryMakesComponent } from './components/content-block-inventory-makes/content-block-inventory-makes.component';
import { ContentBlockInventoryBrowseComponent } from './components/content-block-inventory/content-block-inventory-browse.component';
import { ContentBlockInventoryCardComponent } from './components/content-block-inventory/content-block-inventory-card.component';
import { ContentBlockInventoryDetailComponent } from './components/content-block-inventory/content-block-inventory-detail.component';
import { ContentBlockInventorySearchFormSelectComponent } from './components/content-block-inventory/content-block-inventory-form-select.component';
import { ContentBlockInventoryListComponent } from './components/content-block-inventory/content-block-inventory-list.component';
import { ContentBlockInventorySearchFormComponent } from './components/content-block-inventory/content-block-inventory-search-form.component';
import { ContentBlockInventoryComponent } from './components/content-block-inventory/content-block-inventory.component';
import { ContentBlockLinkComponent } from './components/content-block-link/content-block-link.component';
import { ContentBlockListComponent } from './components/content-block-list/content-block-list.component';
import { ContentBlockLoadBarComponent } from './components/content-block-load-bar/content-block-load-bar.component';
import { ContentBlockLocationDetailComponent } from './components/content-block-location/content-block-location-detail.component';
import { ContentBlockLocationComponent } from './components/content-block-location/content-block-location.component';
import { ContentBlockMainNavigationComponent } from './components/content-block-main-navigation/content-block-main-navigation.component';
import { ContentBlockMissingComponent } from './components/content-block-missing/content-block-missing.component';
import { ContentBlockPageCardComponent } from './components/content-block-page/content-block-page-card.component';
import { ContentBlockPageIndexComponent } from './components/content-block-page/content-block-page-index.component';
import { ContentBlockPageComponent } from './components/content-block-page/content-block-page.component';
import { ContentBlockPaginationComponent } from './components/content-block-pagination/content-block-pagination.component';
import { ContentBlockQuoteComponent } from './components/content-block-quote/content-block-quote.component';
import { ContentBlockRowComponent } from './components/content-block-row/content-block-row.component';
import { ContentBlockScriptComponent } from './components/content-block-script/content-block-script.component';
import { ContentBlockSectionComponent } from './components/content-block-section/content-block-section.component';
import { ContentBlockShareComponent } from './components/content-block-share/content-block-share.component';
import { ContentBlockSiteComponent } from './components/content-block-site/content-block-site.component';
import { ContentBlockSliderComponent } from './components/content-block-slider/content-block-slider.component';
import { ContentBlockTitleBarComponent } from './components/content-block-title-bar/content-block-title-bar.component';
import { ContentBlockYouTubeComponent } from './components/content-block-youtube/content-block-youtube.component';
import { ContentBlockComponent } from './components/content-block/content-block.component';
import {
  PublicPageDataResolver,
  PublicSiteDataResolver,
} from './services/public-app.resolver';
import { ContentBlockStaffBrowserComponent } from './components/content-block-staff/content-block-staff-browser.component';
import { ContentBlockStaffCardComponent } from './components/content-block-staff/content-block-staff-card.component';
import { ContentBlockVideoComponent } from './components/content-block-video/content-block-video.component';
import { ContentBlockBlogVideoModalComponent } from './components/content-block-video/content-block-video-modal.component';
import {
  ContentBlockStaffDetailModalComponent,
  EditUserProfileComponent,
} from './components/content-block-staff/content-block-staff-detail-modal.component';
import { environment } from '@ds/public-env';
import { ContentBlockFirstDataPaymentFormComponent } from './components/content-block-first-data/content-block-first-data-payment-form.component';
import { ContentBlockLocationListComponent } from './components/content-block-location-list/content-block-location-list.component';
import { ContentBlockLocationMapComponent } from './components/content-block-location-map/content-block-location-map.component';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';

import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faRedditAlien } from '@fortawesome/free-brands-svg-icons/faRedditAlien';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons/faLinkedinIn';
import { faGooglePlusG } from '@fortawesome/free-brands-svg-icons/faGooglePlusG';
import { faTumblr } from '@fortawesome/free-brands-svg-icons/faTumblr';
import { faPinterestP } from '@fortawesome/free-brands-svg-icons/faPinterestP';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons/faWhatsapp';
import { faVk } from '@fortawesome/free-brands-svg-icons/faVk';
import { faFacebookMessenger } from '@fortawesome/free-brands-svg-icons/faFacebookMessenger';
import { faTelegramPlane } from '@fortawesome/free-brands-svg-icons/faTelegramPlane';
import { faMix } from '@fortawesome/free-brands-svg-icons/faMix';
import { faXing } from '@fortawesome/free-brands-svg-icons/faXing';
import { faLine } from '@fortawesome/free-brands-svg-icons/faLine';

import { faCommentAlt } from '@fortawesome/free-solid-svg-icons/faCommentAlt';
import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons/faEllipsisH';
import { faLink } from '@fortawesome/free-solid-svg-icons/faLink';
import { faExclamation } from '@fortawesome/free-solid-svg-icons/faExclamation';
import { faPrint } from '@fortawesome/free-solid-svg-icons/faPrint';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';

import { IShareButtons, ShareButtonsConfig } from 'ngx-sharebuttons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { ContentBlockSignatureComponent } from './components/content-block-form/content-block-signature.component';
import { ContentBlockStaffEditComponent } from './components/content-block-staff/content-block-staff-edit.component';
import { ContentBlockLocationDetailTwoComponent } from './components/content-block-location/content-block-location-detail-2.component';
import { ContentBlockSimpleTextComponent } from './components/content-block-simple-text/content-block-simple-text.component';
import { ContentBlockSectionSliderComponent } from './components/content-block-section/content-block-section-slider.component';

const includedAccounts = [
  'facebook',
  'twitter',
  'pinterest',
  'email',
  'print',
  'copy',
  // 'tumblr',
  // 'messenger',
  // 'reddit',
  // 'whatsapp',
  // 'vk',
  // 'telegram',
  // 'xing',
];

library.add(faFacebookF);
library.add(faTwitter);
library.add(faLinkedinIn);
library.add(faEnvelope);
library.add(faLink);
library.add(faPrint);
library.add(faPinterestP);
library.add(faCheck);

// export const shareProp: IShareButtons = {
//   facebook: {
//     icon: faFacebookF,
//   },
//   twitter: { icon: faTwitter, text: 'Tweet' },
//   linkedin: { icon: faLinkedinIn },
//   // google: { icon: faGooglePlusG },
//   tumblr: { icon: faTumblr },
//   // mix: { icon: faMix },
//   pinterest: { icon: faPinterestP },
//   reddit: { icon: faRedditAlien },
//   vk: { icon: faVk },
//   telegram: { icon: faTelegramPlane },
//   messenger: { icon: faFacebookMessenger },
//   whatsapp: { icon: faWhatsapp },
//   xing: { icon: faXing },
//   // line: { icon: faLine },
//   sms: { icon: faCommentAlt },
//   email: { icon: faEnvelope },
//   print: { icon: faPrint },
//   copy: { icon: faLink },
// };

const shareConfig: ShareButtonsConfig = {
  include: includedAccounts,
  // prop: shareProp,
  moreButtonIcon: faEllipsisH,
  lessButtonIcon: faMinus,
  twitterAccount: 'truckcentercompanies',
  debug: false,
  theme: 'modern-light',
  size: includedAccounts.length,
};

const contentBlockComponents = [
  ContentBlockFooterComponent,
  ContentBlockSectionComponent,
  ContentBlockSectionSliderComponent,
  ContentBlockComponent,
  ContentBlockImageComponent,
  ContentBlockHTMLComponent,
  ContentBlockListComponent,
  ContentBlockMissingComponent,
  ContentBlockLinkComponent,
  ContentBlockCardComponent,
  ContentBlockHeaderComponent,
  ContentBlockContactCardComponent,
  ContentBlockGalleryComponent,
  ContentBlockColumnComponent,
  ContentBlockYouTubeComponent,
  ContentBlockSiteComponent,
  ContentBlockMainNavigationComponent,
  ContentBlockLoadBarComponent,
  ContentBlockPageComponent,
  ContentBlockTitleBarComponent,
  ContentBlockQuoteComponent,
  ContentBlockRowComponent,
  ContentBlockInventoryComponent,
  ContentBlockInventoryCardComponent,
  ContentBlockInventoryBrowseComponent,
  ContentBlockInventoryDetailComponent,
  ContentBlockInventorySearchFormComponent,
  ContentBlockInventoryListComponent,
  ContentBlockInventorySearchFormSelectComponent,
  ContentBlockFinancingCalculatorComponent,
  ContentBlockBlogActiveFiltersComponent,
  ContentBlockBlogBrowseComponent,
  ContentBlockBlogDetailComponent,
  ContentBlockBlogComponent,
  ContentBlockBlogCardComponent,
  ContentBlockBlogDatesComponent,
  ContentBlockBlogDatesModalComponent,
  ContentBlockBlogTagsComponent,
  ContentBlockBlogTagsModalComponent,
  ContentBlockPaginationComponent,
  ContentBlockShareComponent,
  ContentBlockContactFormComponent,
  ContentBlockLocationComponent,
  ContentBlockLocationDetailComponent,
  ContentBlockPageIndexComponent,
  ContentBlockPageCardComponent,
  ContentBlockWebsiteContactsComponent,
  ContentBlockIFrameComponent,
  ContentBlockScriptComponent,
  ContentBlockInventoryMakesComponent,
  ContentBlockSliderComponent,
  ContentBlockFormComponent,
  ContentBlockFileUploadComponent,
  ContentBlockSignatureComponent,
  ContentBlockFolderBrowserComponent,
  ContentBlockStaffBrowserComponent,
  ContentBlockStaffCardComponent,
  ContentBlockVideoComponent,
  ContentBlockBlogVideoModalComponent,
  ContentBlockStaffDetailModalComponent,
  ContentBlockFirstDataPaymentFormComponent,
  ContentBlockLocationDetailTwoComponent,
  ContentBlockSimpleTextComponent,
  EditUserProfileComponent,
];

export function publicUrlMatcher<UrlMatcher>(
  segments: UrlSegment[],
  group: UrlSegmentGroup,
  route: Route
): UrlMatchResult {
  return {
    consumed: segments,
    posParams: {
      slug: segments[0],
      ...(segments[1] && {
        p1: segments[1],
      }),
      ...(segments[2] && {
        p2: segments[2],
      }),
      ...(segments[3] && {
        p3: segments[3],
      }),
      ...(segments[4] && {
        p4: segments[4],
      }),
    },
  };
}

const pageRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    resolve: {
      pageData: PublicPageDataResolver,
    },
    component: ContentBlockPageComponent,
  },
  {
    matcher: publicUrlMatcher,
    component: ContentBlockPageComponent,
    resolve: {
      pageData: PublicPageDataResolver,
    },
  },
];

// ToDo: Move urlMatcher route down below a :page route
const routes: Routes = [
  // Add because I thoughts I wanted to be able access
  // other sites without using their default domain, but instead
  // i want to use default domain.
  // {
  //   path: 'site/:siteId',
  //   resolve: {
  //     siteData: PublicSiteDataResolver,
  //   },
  //   component: ContentBlockSiteComponent,
  //   children: pageRoutes,
  // },
  {
    path: '',
    resolve: {
      siteData: PublicSiteDataResolver,
    },
    component: ContentBlockSiteComponent,
    children: pageRoutes,
  },
];

const formConfig: ConfigOption = {
  types: [
    {
      name: 'file-upload',
      component: ContentBlockFileUploadComponent,
      wrappers: ['form-field'],
    },
    {
      name: 'signature',
      component: ContentBlockSignatureComponent,
      wrappers: ['form-field'],
    },
  ],
};

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    InlineSVGModule.forRoot({
      baseUrl: environment.production && 'https://cloud.dealersupra.com',
    }),
    CoreModule,
    ShareButtonsModule.withConfig(shareConfig),
    FormlyModule.forChild(formConfig),
    RouterModule.forChild(routes),
  ],
  exports: [...contentBlockComponents],
  entryComponents: [...contentBlockComponents],
  declarations: [
    ...contentBlockComponents,
    ContentBlockLocationListComponent,
    ContentBlockLocationMapComponent,
    ContentBlockStaffEditComponent,
  ],
})
export class PublicContentBlockModule {}
