import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PageService } from '@ds/api-services';
import { filterNullOrUndefined } from '@ds/core';
import { PageTypes, PublicRouteParams } from '@ds/interfaces';
import { Observable } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  switchMap,
  tap,
  publishReplay,
  refCount,
} from 'rxjs/operators';
import { ContentBlockPageService } from '../../services/content-block-page.service';

// /slug/
// /slug/article-slug
// /slug/page?tag?

/**
 * Pagination Note
 * We keep query param "?page=1" and the UX the same. Despite the fact
 * that the server start pagination at ZERO. So when the user see's page
 * 2, we are actually asking for page 1 from the server. And since page 1
 * is root, we filter that out when generating links
 */

export interface BlogSearchParams {
  page?: number;
  limit?: number;
  tag?: string;
  year?: string;
  month?: string;
}

const defaultBlogSearchParams: BlogSearchParams = {};

const queryParamKeys = ['page', 'tag', 'year', 'month'];

@Injectable()
export class ContentBlockBlogService {
  blogPageSlug: string;

  blogConfig$ = this.pageService.blogConfig();

  public isBrowsing$ = this.route.params.pipe(
    map(
      (params: PublicRouteParams) =>
        params.slug === this.blogPageSlug &&
        (!params.p1 || params.p1.length === 0)
    ),
    distinctUntilChanged()
  );

  public routeParams$: Observable<BlogSearchParams> = this.route.params.pipe(
    map((params: PublicRouteParams): BlogSearchParams => {
      if (params.slug !== this.blogPageSlug) {
        return null;
      }

      if (params.p1 && params.p1.length) {
        return null;
      }

      const p: BlogSearchParams = queryParamKeys.reduce((a, c) => {
        if (params[c]) {
          a[c] = params[c];
        }
        return a;
      }, {});

      this.storeParams(p);

      this.cbPageService.setTitleAndStuff();

      return p;
    }),
    filterNullOrUndefined(),
    publishReplay(1),
    refCount()
  );

  entries$ = this.routeParams$.pipe(
    map((params) => ({
      limit: 12,
      page: 1,
      ...params,
    })),
    switchMap((params) =>
      this.pageService.findAll({
        type: PageTypes.BLOG_ENTRY,
        sortProp: 'publishedOn',
        sortOrder: 'desc',
        page: +params.page > 0 ? params.page - 1 : 0,
        limit: params.limit,
        tag: params.tag,
        year: params.year,
        month: params.month,
      })
    ),
    filterNullOrUndefined()
  );

  currentEntrySlug$ = this.route.params.pipe(
    map((params: PublicRouteParams) => {
      if (params.slug !== this.blogPageSlug) {
        return null;
      }

      if (!params.p1 || params.p1.length === 0) {
        return null;
      }

      return params.p1;
    }),
    filterNullOrUndefined(),
    distinctUntilChanged()
  );

  currentEntry$ = this.currentEntrySlug$.pipe(
    switchMap((id) => this.pageService.findOne(id)),
    tap((item) => {
      this.cbPageService.setTitleAndStuff({
        title: item.title,
        imageHandle: item.config.imageHandle,
        description: item.config.description,
      });
    })
  );

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private cbPageService: ContentBlockPageService,
    private pageService: PageService
  ) {
    this.blogPageSlug = this.route.snapshot.params.slug;
  }

  updateStore(updatedParams: BlogSearchParams) {
    const paths = this.generateRoutesFromStore(updatedParams);
    return this.router.navigate(paths);
  }

  generateRoutesFromStore(updatedParams: BlogSearchParams = {}) {
    const slug = this.blogPageSlug;
    const params = {
      ...this.currentParams,
      ...updatedParams,
    };
    const queryParams = queryParamKeys.reduce((a, c) => {
      if (params[c]) {
        a[c] = params[c];
      }
      return a;
    }, {});

    return ['/', slug, queryParams];
  }

  get currentParams() {
    return this.cbPageService.getBlogParams() || {};
  }

  storeParams(params: BlogSearchParams) {
    this.cbPageService.storeBlogParams(params);
  }

  resetSearch() {
    this.cbPageService.storeBlogParams({});
  }

  nextPage() {
    const page = (+this.currentParams.page || 1) + 1;
    return this.generateRoutesFromStore({ page });
  }

  previousPage() {
    // Page 1 should not get a query param, so if the current page is "2", then
    // we will just return null. See note about pagination at top of page.
    const page =
      +this.currentParams.page <= 2 ? null : +this.currentParams.page - 1;
    return this.generateRoutesFromStore({ page });
  }
}
