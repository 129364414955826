import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  ElementRef,
  Input,
  Renderer2,
  AfterViewInit,
  NgZone,
  Output,
  EventEmitter,
  PLATFORM_ID,
  Inject,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RetailLocation } from '@ds/interfaces';
import { Dictionary } from 'lodash';

@Component({
  selector: 'ds-cb-content-block-location-map',
  template: `<ds-cb-card backgroundColor="transparent" [showBorder]="false"
    ><div id="ds-us-map"></div
  ></ds-cb-card>`,
  styles: [
    `
      :host {
        display: block;
        position: relative;
      }
      div {
        position: relative;
      }
    `,
  ],
})
export class ContentBlockLocationMapComponent implements AfterViewInit {
  @Input() locations: RetailLocation[];
  @Input() slug: string;

  @Output() stateSelected: EventEmitter<string> = new EventEmitter();
  @Output() backSelected: EventEmitter<null> = new EventEmitter();

  locationMap: Dictionary<RetailLocation> = {};

  constructor(
    private renderer2: Renderer2,
    private hostElement: ElementRef,
    private zone: NgZone,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.loadData();
    }
  }

  loadData() {
    const tagId = 'ds-map-data-script';
    const exists = window.document.getElementById(tagId);
    if (exists) {
      return;
    }
    const s = this.renderer2.createElement('script') as HTMLScriptElement;
    s.type = 'text/javascript';
    s.src = '/assets/scripts/mapdata.js';
    s.id = tagId;
    s.onload = () => this.loadScript();
    s.text = ``;
    this.hostElement.nativeElement.appendChild(s);
  }

  loadScript() {
    const tagId = 'ds-map-script';
    const exists = window.document.getElementById(tagId);
    if (exists) {
      return;
    }
    const s = this.renderer2.createElement('script') as HTMLScriptElement;
    s.type = 'text/javascript';
    s.src = '/assets/scripts/usmap.js';
    s.id = tagId;
    s.onload = () => this.scriptLoaded();
    s.text = ``;
    this.hostElement.nativeElement.appendChild(s);
  }

  scriptLoaded() {
    console.log('Script Loaded');
    this.loadHooks();
  }

  loadHooks() {
    window['simplemaps_usmap'].hooks.ready = () => this.loadLocationInfo();
    window['simplemaps_usmap'].hooks.click_location = (id: string) =>
      this.locationClicked(id);
    window['simplemaps_usmap'].hooks.zoomable_click_state = (id: string) =>
      this.stateClicked(id);
    window['simplemaps_usmap'].hooks.back = () => this.backClicked();
  }

  loadLocationInfo() {
    this.locations
      .filter((l) => l.config.lat && l.config.lng)
      .forEach((l, index) => {
        this.locationMap[l.id.toString()] = l;
        window['simplemaps_usmap_mapdata'].locations[l.id.toString()] = {
          lat: l.config.lat,
          lng: l.config.lng,
          name: l.name,
          description: `${l.address.city}, ${l.address.state}`,
        };
      });
    window['simplemaps_usmap_mapinfo'].initial_view = {
      x: 350,
      y: 50,
      x2: 600,
      y2: 350,
    };
    this.loadMap();
  }

  loadMap() {
    this.zone.run(() => {
      window['simplemaps_usmap'].load();
    });
  }

  locationClicked(id: string) {
    const l = this.locationMap[id];
    this.router.navigate([l.slug], {
      relativeTo: this.route,
    });
  }

  stateClicked(id: string): void {
    const state = window['simplemaps_usmap_mapdata'].state_specific[id].name;
    this.stateSelected.emit(state);
  }

  backClicked() {
    this.backSelected.emit();
  }
}
