import {
  AfterContentInit,
  Component,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
  HostBinding,
  ViewEncapsulation,
  ChangeDetectionStrategy,
} from '@angular/core';
import { BlockTypeSection, TContentBlock } from '@ds/interfaces';
import { ContentBlockDataService } from '../../services/content-block-data.service';
import { ContentBlockRenderService } from '../../services/content-block-render.service';
import { switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'ds-cb',
  template: `
    <ng-container *ngIf="isEditingActive$ | async"></ng-container>
    <ng-template #content></ng-template>
    <!-- <div class="edit-shield"></div> -->
  `,
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
      @keyframes cb-blink {
        50% {
          border-color: rgb(255, 0, 0, 0.25);
        }
      }
      ds-cb {
        display: block;
        position: relative;
        width: 100%;
      }
      .is-editing ds-cb {
        border: 1px solid transparent;
      }
      .is-editing ds-cb:hover {
        border: 1px solid black;
      }
      .is-editing ds-cb.is-editing-active {
        border: 1px solid rgb(255, 0, 0, 1);
        animation: cb-blink 1s linear infinite alternate;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockComponent implements OnDestroy, OnChanges {
  @Input() block: TContentBlock;

  @ViewChild('content', { read: ViewContainerRef, static: true })
  contentTpl: ViewContainerRef;

  @HostBinding('class.is-editing-active') isEditingActive: boolean;
  @HostBinding('class.is-editing-child') isEditingChild: boolean;

  @HostBinding('class') get size() {
    return (
      (this.block &&
        this.block.type !== BlockTypeSection &&
        this.block.size &&
        `ds-col-sm-${this.block.size}`) ||
      'ds-col'
    );
  }

  isEditingActive$ = this.cbDataService.highlightedCb.pipe(
    tap(
      (id: string | number) =>
        (this.isEditingActive = this.block && this.block.id === id)
    ),
    tap((id) => {
      this.isEditingChild =
        this.block && this.hasParent(this.getBlock(id), this.block.id);
    })
  );

  getBlock(id: string | number) {
    return this.cbDataService.state.content.find((b) => b.id === id);
  }

  getParent(parentId: string | number, blocks: TContentBlock[]): TContentBlock {
    return blocks.find((b) => b.id === parentId);
  }

  hasParent(block: TContentBlock, parentId: string | number): boolean {
    if (!block) return false;
    if (!block.parentId) return false;
    if (block.parentId === parentId) return true;
    return this.hasParent(
      this.getParent(block.parentId, this.cbDataService.state.content),
      parentId
    );
  }

  @HostListener('click', ['$event'])
  onClick(e: MouseEvent) {
    if (this.cbDataService.selectContentBlock(this.block)) {
      e.preventDefault();
      e.stopImmediatePropagation();
    }
  }

  constructor(
    private contentService: ContentBlockRenderService,
    private cbDataService: ContentBlockDataService
  ) {}

  ngOnDestroy() {
    if (this.contentTpl) this.contentTpl.clear();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.block &&
      !deepEqual(changes.block.currentValue, changes.block.previousValue)
    ) {
      if (!changes.block.firstChange) {
        console.log('[CB Changed!]');
      }
      this.contentService.renderContentBlock(this.contentTpl, this.block);
    }
  }
}

function deepEqual(a: any, b: any): boolean {
  if (a === b) return true;

  if (a == null || typeof a !== 'object' || b == null || typeof b !== 'object')
    return false;

  const keysA = Object.keys(a),
    keysB = Object.keys(b);

  if (keysA.length !== keysB.length) return false;

  for (const key of keysA) {
    if (!keysB.includes(key) || !deepEqual(a[key], b[key])) return false;
  }

  return true;
}
