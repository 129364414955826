import { Injectable, Inject, PLATFORM_ID, Optional } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { PageService, SiteService, AuthService } from '@ds/api-services';
import { Page, Site, SiteTheme, SiteConfig, PageTypes } from '@ds/interfaces';
import { Observable, throwError, of } from 'rxjs';
import { catchError, tap, switchMap, map } from 'rxjs/operators';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { REQUEST, RESPONSE } from '@nguniversal/hapi-engine/tokens';
import { ContentBlockWebsiteEditService } from './content-block-website-edit.service';
import { ContentBlockSiteService } from './content-block-site.service';

@Injectable({
  providedIn: 'root',
})
export class PublicPageDataResolver implements Resolve<Observable<Page>> {
  constructor(
    private pageService: PageService,
    private siteService: SiteService,
    private cbWsEditService: ContentBlockWebsiteEditService,
    @Inject(PLATFORM_ID) private platformId: any,
    @Optional() @Inject(RESPONSE) private response: any,
    @Optional() @Inject(REQUEST) private request: any
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Page> {
    const pageId =
      route.paramMap.get('slug') || this.siteService.currentSite.homePage.slug;
    const pageData$ = this.pageService.findOne(pageId).pipe(
      tap((pageData) => {
        if (isPlatformBrowser(this.platformId)) {
          // c.group('[PageDataResolver]');
          // c.log('Param Map: ', route.paramMap);
          // c.log('Query Map: ', route.queryParamMap);
          // c.log('PageData: ', pageId, pageData);
          // c.groupEnd();
        }
      }),
      tap((pageData) => {
        if (pageData.type === PageTypes.REDIRECT) {
          console.log('[PageDataResolver] : Redirect to ', pageData.config.url);
          if (this.cbWsEditService.isEditing) {
            console.log(
              '[PageDataResolver] : Not Redirecting. Currently Editing. '
            );
          } else {
            if (isPlatformBrowser(this.platformId)) {
              window.open(pageData.config.url);
            }
          }
        }
      }),
      catchError((error) => {
        if (isPlatformServer(this.platformId) && error.status === 401) {
          redirectToLogin(this.request, this.response);
        }

        return throwError(error);
      })
    );
    return pageData$;
  }
}

@Injectable({
  providedIn: 'root',
})
export class PublicSiteDataResolver implements Resolve<Observable<Site>> {
  constructor(
    private authService: AuthService,
    private siteService: SiteService,
    private cbSiteService: ContentBlockSiteService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: any,
    @Optional() @Inject(RESPONSE) private response: any,
    @Optional() @Inject(REQUEST) private request: any
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Site> {
    // Site ID added to I could access different sites
    // from the same domain. I don't think I will be doing
    // this going forward. Note siteId is optional. It normally
    // null and we just get the siteId from the hostname
    const siteId = route.params.siteId;
    return this.siteService.findOne(siteId).pipe(
      switchMap((site) => {
        if (site.requiredAuth) {
          return this.authService.getSession().pipe(map(() => site));
        }
        return of(site);
      }),
      tap((site) => {
        if (isPlatformBrowser(this.platformId)) {
          this.cbSiteService.loadSiteConfig(site.config);
        }
        this.siteService.setCurrentSite(site);
      }),
      catchError((error) => {
        if (isPlatformServer(this.platformId) && error.status === 401) {
          redirectToLogin(this.request, this.response);
        }

        return throwError(error);
      })
    );
  }
}

function redirectToLogin(request: any, response: any): void {
  const url = getLoginRedirectUrl(request);
  response.writeHead(302, { Location: url });
  response.end();
}

function getLoginRedirectUrl(request: any): string {
  const protocol =
    request.headers['x-forwarded-proto'] || request.server.info.protocol;

  return `${protocol}://${request.server.app._currentHost.name}/login?redirect=${request.path}`;
}
