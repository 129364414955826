import { isPlatformBrowser } from '@angular/common';
import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  PLATFORM_ID,
} from '@angular/core';
import { FieldType } from '@ngx-formly/material';
import * as SignaturePadNative from 'signature_pad';

@Component({
  template: `
    <pre>{{ to.signatureText }}</pre>
    <div><canvas></canvas></div>
    <button class="ds-button alt" role="button" (click)="clear($event)">
      Clear Signature
    </button>
    Use your mouse to sign inside this block
    <img id="test-image" />
  `,
  styles: [
    `
      button {
        margin-right: 15px;
      }
      canvas {
        height: 150px;
        width: 500px;
        border: 2px dotted #cccccc;
        border-radius: 15px;
        cursor: crosshair;
      }
      pre {
        margin: 5px 15px;
        white-space: pre-wrap; /* css-3 */
        word-wrap: break-word; /* Internet Explorer 5.5+ */
      }
    `,
  ],
})
export class ContentBlockSignatureComponent
  extends FieldType
  implements AfterContentInit, OnDestroy
{
  tagId = Math.random().toString(36).substring(2);
  scriptId = 'sig-pad-script';

  signaturePad: any;

  constructor(
    private hostElement: ElementRef,
    @Inject(PLATFORM_ID) private platformId: Object,
    private cd: ChangeDetectorRef
  ) {
    super();
  }

  ngAfterContentInit() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    const canvas: any = this.hostElement.nativeElement.querySelector('canvas');
    this.signaturePad = new SignaturePadNative.default(canvas, {
      backgroundColor: 'rgb(255,255,255)',
    });
    this.signaturePad.onBegin = this.onBegin.bind(this);
    this.signaturePad.onEnd = this.onEnd.bind(this);
    const ratio = Math.max(window.devicePixelRatio || 1, 1);
    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    canvas.getContext('2d').scale(ratio, ratio);
    this.signaturePad.clear();
  }

  ngOnDestroy(): void {
    const canvas: any = this.hostElement.nativeElement.querySelector('canvas');
    canvas.width = 0;
    canvas.height = 0;
  }

  public onBegin(): void {
    console.log('started');
  }

  public onEnd(): void {
    this.updateFormValue();
  }

  public clear(ev: MouseEvent): void {
    ev.preventDefault();
    ev.stopPropagation();
    this.signaturePad.clear();
    this.updateFormValue();
  }

  public isEmpty(): boolean {
    return this.signaturePad.isEmpty();
  }

  // Returns signature image as data URL (see https://mdn.io/todataurl for the list of possible parameters)
  public toDataURL(imageType?: string, quality?: number): string {
    return this.signaturePad.toDataURL(imageType, quality); // save image as data URL
  }

  updateFormValue() {
    this.formControl.markAsDirty();
    this.formControl.markAsTouched();
    this.formControl.patchValue(
      this.isEmpty() ? null : this.toDataURL('image/jpeg', 0.5)
    );
    this.cd.markForCheck();
  }
}
