import { BreakpointObserver } from '@angular/cdk/layout';
import { isPlatformServer } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {
  NavigationEnd,
  Router,
  NavigationStart,
  ActivatedRoute,
} from '@angular/router';

import { Site, MainNavLink, Page, Profile } from '@ds/interfaces';
import { ContentBlockWebsiteEditService } from '../../services/content-block-website-edit.service';
import { pluck, tap } from 'rxjs/operators';
import { DsMenuComponent } from 'libs/core/src/lib/components/menu/ds-menu.component';
import { AuthService } from '@ds/api-services';
import { environment } from '@ds/admin-env';
import { ModalService } from '@ds/core';
import { ContentBlockStaffDetailModalComponent } from '../content-block-staff/content-block-staff-detail-modal.component';

/**
 * Nav Bar for public site
 * will add 'homepage' class when on  home page
 * will add 'mobile' class when less than med
 */

@Component({
  selector: 'ds-cb-content-main-navigation',
  template: `<ds-cb-load-bar [loading]="isNavigating"> LOADING </ds-cb-load-bar>
    <div
      class="nav-container"
      [class.mobile]="renderSmallScreen"
      [class.desktop]="!renderSmallScreen"
      [class.homepage]="isHomePage"
      [class.logo-left]="siteData.config.logoPosition === 'left'"
    >
      <div class="mobile-logo-container" *ngIf="renderSmallScreen">
        <div
          role="button"
          class="icon"
          inlineSVG="/icons/menu.svg"
          role="button"
          (click)="showMobileNav = !showMobileNav"
        ></div>
        <a [href]="createUrl('')" (click)="navClick($event)">
          <div
            class="logo"
            [style.background-image]="getSafeUrl(this.siteData.logoHandle)"
          ></div>
        </a>
      </div>
      <nav
        (click)="showMobileNav = false"
        [class.is-open]="renderSmallScreen && showMobileNav"
      >
        <ul class="menu">
          <ng-container *ngFor="let link of desktopLinksLeft">
            <ng-container
              *ngTemplateOutlet="linkTemplateRef; context: { $implicit: link }"
            ></ng-container>
          </ng-container>

          <li *ngIf="!renderSmallScreen" class="logo-container">
            <a href="/" (click)="navClick($event)">
              <div
                class="logo"
                [style.background-image]="getSafeUrl(this.siteData.logoHandle)"
              ></div>
            </a>
          </li>

          <ng-container *ngFor="let link of desktopLinksRight">
            <ng-container
              *ngTemplateOutlet="linkTemplateRef; context: { $implicit: link }"
            ></ng-container>
          </ng-container>
          <li class="menu-item" *ngIf="currentUser">
            <ng-container *ngIf="renderSmallScreen">
              <a href="">ME</a>
            </ng-container>
            <ng-container *ngIf="!renderSmallScreen">
              <a href="" [dsMenuTriggerFor]="subUserNav">
                <!-- <div class="icon" inlineSVG="link.icon"></div> -->
                <div class="label">{{ currentUser.displayName }}</div>
              </a>
            </ng-container>
            <ds-menu #subUserNav="dsMenu" class="sub-menu">
              <a dsMenuItem href="#" (click)="myProfile($event)">My Profile</a>
              <a dsMenuItem href="#" (click)="logout($event)">Logout</a>
            </ds-menu>
          </li>
        </ul>
      </nav>
    </div>

    <ng-template #linkTemplateRef let-link>
      <li class="menu-item" [class.active]="isPageActive(link)">
        <ng-container *ngIf="link.navigation && link.navigation.length > 0">
          <ng-container *ngIf="renderSmallScreen">
            <a [href]="link.slug" (click)="navClick($event, link.slug)">
              <div *ngIf="link.icon" class="icon" [inlineSVG]="link.icon"></div>
              <div class="label">{{ link.title }}</div>
              <button
                class="sub-menu-trigger"
                [class.sub-menu-trigger--open]="subNavTrigger.menuOpen"
                #subNavTrigger="dsMenuTrigger"
                [dsMenuTriggerFor]="subNav"
                [trigger]="'click'"
                [mode]="'in-place'"
              >
                <div [inlineSVG]="'/icons/icon-carret.svg'"></div>
              </button>
            </a>
          </ng-container>

          <ng-container *ngIf="!renderSmallScreen">
            <a
              [href]="link.slug"
              [dsMenuTriggerFor]="subNav"
              (click)="navClick($event, link.slug)"
            >
              <div *ngIf="link.icon" class="icon" [inlineSVG]="link.icon"></div>
              <div class="label">{{ link.title }}</div>
              <button
                class="sub-menu-trigger"
                [class.sub-menu-trigger--open]="subNavTrigger.menuOpen"
                #subNavTrigger="dsMenuTrigger"
                [dsMenuTriggerFor]="subNav"
                [trigger]="'click'"
                [mode]="'in-place'"
              >
                <div
                  *ngIf="!isLegacy"
                  [inlineSVG]="'/icons/icon-carret.svg'"
                ></div>
              </button>
            </a>
          </ng-container>

          <ds-menu #subNav="dsMenu" class="sub-menu">
            <a
              dsMenuItem
              *ngFor="let subLink of link.navigation"
              [href]="createUrl(subLink.slug)"
              (click)="navClick($event, subLink.slug, subNav)"
              [class.active]="subLink.slug === currentSlug"
              >{{ subLink.title }}</a
            >
          </ds-menu>
        </ng-container>

        <ng-container *ngIf="!link.navigation || !link.navigation.length">
          <a
            [href]="createUrl(link.slug)"
            (click)="navClick($event, link.slug)"
          >
            <div *ngIf="link.icon" class="icon">
              <div class="main-nav-icon" [inlineSVG]="link.icon"></div>
            </div>
            <div class="label">{{ link.title }}</div>
          </a>
        </ng-container>
      </li>
    </ng-template> `,
  styles: [
    /* https://css-tricks.com/gotchas-on-getting-svg-into-production/ */
    `
      :host {
        background-color: var(--ds-cb-nav-background-color);
        display: block;
      }
      .nav-container {
        display: block;
        color: var(--ds-cb-nav-text-color);
        max-width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        fill: currentColor;
      }
      .menu {
        display: flex;
        justify-content: space-between;
      }
      .menu-item {
        min-height: 1px;
        opacity: 0.9;
        transition: background-color 0.3s ease;
        // justify-content: space-around;
      }
      .menu-item a {
        padding: 10px 5px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        height: 100%;
        position: relative;
      }
      .menu-item a .label {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      .menu-item a .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
      }

      .mobile {
        .menu-item a .sub-menu-trigger {
          position: absolute;
          right: 0;
        }
      }

      .desktop {
        .menu-item a .sub-menu-trigger {
          padding-inline-end: 0;
        }
      }

      .menu-item a .sub-menu-trigger {
        display: flex;
        background: none;
        border: none;
        color: #fff;
      }
      .menu-item a .sub-menu-trigger--open > div {
        transform: rotate(180deg);
      }
      .menu-item:hover {
        opacity: 1;
      }
      .menu-item:hover > a {
        background-color: var(--ds-cb-nav-item-background-color--hover);
      }
      .menu-item:hover a {
        color: var(--ds-cb-nav-item-color--hover);
        fill: currentColor;
      }
      .menu-item.active {
        opacity: 1;
      }
      .menu-item.active > a {
        background: var(--ds-cb-nav-item-background-color--active);
      }
      .menu-item.active a {
        color: var(--ds-cb-nav-item-color--active);
        fill: currentColor;
      }
      .sub-menu-trigger {
        float: right;
        cursor: pointer;
      }
      ::ng-deep .ds-menu.sub-menu {
        background-color: var(--ds-cb-nav-sub-menu-background-color);
      }
      ::ng-deep .ds-menu.sub-menu a {
        color: var(--ds-cb-nav-sub-menu-color);
      }
      ::ng-deep .ds-menu-item:hover {
        border-left: var(--ds-cb-nav-sub-menu-item-border-left--hover);
      }
      ::ng-deep .ds-menu-item.active {
        border-left: var(--ds-cb-nav-sub-menu-item-border-left--hover);
      }
      a {
        font-size: 15px;
        font-weight: bold;
        text-transform: uppercase;
        text-decoration: none;
      }
      .logo-container {
        background: var(--ds-cb-nav-background-color);
        z-index: 0;
        display: flex;
        align-items: center;
        margin: 0 10px;
      }
      .logo-container > a {
        // margin: 0 auto;
      }
      .logo {
        display: inline-block;
        max-width: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        padding: 5px;
        transition: transform 0.3s ease;
      }
      .logo:hover {
        transform: scale(1.1);
      }
      .icon {
        display: inline-block;
        width: 50px;
        height: 50px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }
      .main-nav-icon {
        width: 33px;
        height: 27px;
      }
      .mobile .logo-container {
        min-width: 200px;
      }
      .mobile .logo {
        height: var(--ds-cb-nav-logo-height);
        width: 200px;
        min-width: 200px;
      }
      .mobile .menu {
        flex-direction: column;
      }
      .mobile .menu-item a {
        flex-direction: row;
        justify-content: flex-start;
        padding: 15px;
      }
      .mobile nav {
        display: none;
      }
      .mobile nav.is-open {
        display: block;
      }
      .desktop .logo-container {
        min-width: 200px;
      }
      .desktop .logo {
        height: var(--ds-cb-nav-logo-height);
        width: 350px;
        min-width: 200px;
      }
      :host-context(.ds-menu-position-left) ul .logo-container,
      .nav-container.logo-left ul .logo-container {
        order: -1;
        flex-grow: 1;
      }
      :host-context(.ds-menu-position-left) .menu-item,
      .nav-container.logo-left .menu-item {
        max-width: 150px;
      }
      :host-context(.ds-menu-position-left) .menu-item a,
      .nav-container.logo-left .menu-item a {
        padding-left: 15px;
        padding-right: 15px;
      }
      :host-context(.ds-menu-position-left).mobile .menu-item,
      .nav-container.logo-left.mobile .menu-item {
        max-width: 100%;
      }
      .nav-container:not(.logo-left) .menu-item {
        flex: 1;
      }
      :host-context(.ds-menu-position-left) {
        width: 150px;
      }
      :host-context(.ds-menu-position-left) .nav-container {
        padding-left: 0;
        padding-right: 0;
      }
      :host-context(.ds-menu-position-left) .menu {
        flex-direction: column;
      }
      :host-context(.ds-menu-position-left) .menu-item {
        padding-top: 15px;
        padding-bottom: 15px;
      }
      :host-context(.ds-menu-position-left) .logo-container {
        margin: 0;
        min-width: 100%;
      }
      :host-context(.ds-menu-position-left) .logo-container > a {
        width: 100%;
      }
      :host-context(.ds-menu-position-left) .logo-container .logo {
        width: 100%;
        min-width: 100%;
      }
      .mobile-logo-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
      .mobile-logo-container .icon {
        position: absolute;
        right: 0;
      }
    `,
  ],
})
export class ContentBlockMainNavigationComponent implements OnInit {
  @Input() siteData: Site;

  isHomePage: boolean;
  desktopLinksLeft: MainNavLink[];
  desktopLinksRight: MainNavLink[];
  mobilePrimaryLink: MainNavLink;
  renderSmallScreen: boolean;
  showMobileNav = false;
  isNavigating = false;
  currentSlug: string;
  currentUser: Profile;
  isServer = true;

  get isLegacy() {
    return !this.siteData || this.siteData?.id === 1;
  }

  constructor(
    private sanitizer: DomSanitizer,
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    @Inject(PLATFORM_ID) platformId: Object,
    public wsDataMsg: ContentBlockWebsiteEditService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private modalService: ModalService
  ) {
    this.isServer = isPlatformServer(platformId);
    this.renderSmallScreen =
      !this.isServer || this.breakpointObserver.isMatched('(max-width: 999px)');

    if (!this.isServer) {
      this.currentUser =
        this.authService.session && this.authService.session.profile;
      this.breakpointObserver
        .observe('(max-width: 999px)')
        .subscribe((data) => (this.renderSmallScreen = data.matches));
    }
  }

  ngOnInit() {
    let timer;
    this.currentSlug =
      this.route.children[0] && this.route.children[0].snapshot.params.slug;
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (timer) {
          clearTimeout(timer);
        }
        this.isNavigating = true;
      } else if (event instanceof NavigationEnd) {
        timer = setTimeout(() => {
          this.isNavigating = false;
        }, 500);

        if (this.router.url === '/') {
          this.isHomePage = true;
        }

        this.currentSlug =
          this.route.children[0] && this.route.children[0].snapshot.params.slug;
      }
    });
    const numberOfLinks = this.siteData.navigation.length;
    const firstHalfCount = Math.ceil(numberOfLinks / 2);
    this.desktopLinksLeft = this.siteData.navigation.slice(0, firstHalfCount);
    this.desktopLinksRight = this.siteData.navigation.slice(firstHalfCount);
    this.mobilePrimaryLink = this.siteData.navigation[numberOfLinks - 1];
  }

  getSafeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustStyle(`url(${url})`);
  }
  getSafeResourceUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(`url(${url})`);
  }

  createUrl(slug: string) {
    return '/' + (slug || '');
  }

  navClick(e: MouseEvent, slug: string, subNav: DsMenuComponent) {
    e.preventDefault();
    if (subNav) {
      subNav.close();
    }
    if (this.wsDataMsg.isEditing) {
      e.stopPropagation();
      this.wsDataMsg.adminNavigatePage(slug);
      return;
    }
    this.router.navigateByUrl(this.createUrl(slug));
  }

  isPageActive(navLink: MainNavLink) {
    return (
      navLink.slug === this.currentSlug ||
      navLink.navigation.some((n) => n.slug === this.currentSlug)
    );
  }

  myProfile($event: MouseEvent) {
    console.log('open my profile');
    $event.preventDefault();
    this.modalService.open<void, ContentBlockStaffDetailModalComponent['data']>(
      ContentBlockStaffDetailModalComponent,
      {
        userId: this.currentUser.id,
      }
    );
  }

  // ContentBlockStaffDetailModalComponent

  logout(event: MouseEvent) {
    event.preventDefault();
    this.authService.logout().subscribe(() => this.redirectToLogin());
  }

  redirectToLogin() {
    if (!this.isServer) {
      window.location.href = environment.loginUrl;
    }
  }
}
