import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  SiteUpdateMessageData,
  SiteUpdateMessageTypes,
  Page,
} from '@ds/interfaces';

@Injectable({ providedIn: 'root' })
export class ContentBlockWebsiteEditService {
  isServer = true;
  isEditing = false;

  constructor(@Inject(PLATFORM_ID) private platformId: any) {
    this.isServer = isPlatformServer(this.platformId);
    // ToDo: replace this. We are simply assuming that since
    // we are in an Iframe that we are being edited.
    // this would not necessarily be the case.
    this.isEditing = !this.isServer && window.self !== window.top;

    // if (window.parent) {
    // this.publicNotifyAdminReady()
    // }
  }

  // Used by child, Send message to admin, or editor
  sendMessageUp(data: SiteUpdateMessageData) {
    if (this.isServer) {
      return;
    }
    if (!this.isEditing) {
      return;
    }
    window.parent.postMessage(data, '*');
  }

  // tell admin child is ready for edit
  publicNotifyAdminReady() {
    this.sendMessageUp({
      type: SiteUpdateMessageTypes.ADMIN_NOTIFY_PUBLIC_READY,
    });
  }

  // Tell admin to navigate
  adminNavigateCb(slug: string, cbId: number | string) {
    this.sendMessageUp({
      type: SiteUpdateMessageTypes.ADMIN_NAVIGATE_CB,
      slug,
      cbId,
    });
  }

  // tell admin to navigate page
  // note, then admin will tell public to
  // navigate. This allows admin to cancel
  // the navigation if edits are currently
  // being made.
  adminNavigatePage(slug: string) {
    this.sendMessageUp({
      type: SiteUpdateMessageTypes.ADMIN_NAVIGATE_PAGE,
      slug,
    });
  }

  // send page up to admin for save
  adminSavePage(page: Page) {
    this.sendMessageUp({
      type: SiteUpdateMessageTypes.ADMIN_SAVE_PAGE,
      pageData: page,
    });
  }
}
