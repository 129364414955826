import { Component, OnInit } from '@angular/core';
import { UserService, LocationService } from '@ds/api-services';
import { Observable, of } from 'rxjs';
import { PaginatedResults, User, ExtraPaginatedResults } from '@ds/interfaces';
import { ActivatedRoute, Router } from '@angular/router';
import { map, switchMap, tap } from 'rxjs/operators';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormGroup } from '@angular/forms';
import { object } from '@hapi/joi';

interface CityMap {
  [key: string]: string;
}

interface Filters {
  q?: string;
  locationId?: number[];
  page?: number;
}

@Component({
  selector: 'ds-cb-staff-browser',
  template: `
    <ds-cb-section>
      <div class="ds-row">
        <div class="ds-col">
          <formly-form
            [form]="filterForm"
            [fields]="filterFields"
            [model]="filterModel"
            (modelChange)="updateFilter($event)"
          ></formly-form>
          <ng-container *ngIf="users$ | async as users">
            <ds-cb-pagination
              (previous)="previousPage($event)"
              (next)="nextPage($event)"
              [currentPage]="users.currentPage"
              [totalPages]="users.totalPages"
            ></ds-cb-pagination>
            <div class="user-cards">
              <ds-cb-staff-card
                [user]="user"
                *ngFor="let user of users.results"
              ></ds-cb-staff-card>
            </div>
            <ds-cb-pagination
              [currentPage]="users.currentPage"
              [totalPages]="users.totalPages"
            ></ds-cb-pagination>
          </ng-container>
        </div>
      </div>
    </ds-cb-section>
  `,
  styles: [
    `
      .user-cards {
        margin-top: 15px;
        display: flex;
        flex-wrap: wrap;
      }
      ds-cb-staff-card {
        width: 225px;
        margin-bottom: 15px;
      }
    `,
  ],
})
export class ContentBlockStaffBrowserComponent implements OnInit {
  users$: Observable<ExtraPaginatedResults<User>>;

  cities$: Observable<string[]> = this.locationService.findAll().pipe(
    map(({ results }) => {
      const cityMap: CityMap = results
        .map((l) => l.address.city)
        .reduce((a, c) => {
          if (!a[c]) {
            a[c] = c;
          }
          return a;
        }, {});

      return Object.values(cityMap).sort((a, b) => a.localeCompare(b));
    })
  );

  filterFields: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'ds-row',
      fieldGroup: [
        {
          className: 'ds-col',
          type: 'input',
          key: 'q',
          modelOptions: {
            debounce: {
              default: 500,
            },
          },
          templateOptions: {
            placeholder: 'Search',
          },
        },
        {
          className: 'ds-col',
          key: 'locationCity',
          type: 'select',
          options: {},
          templateOptions: {
            placeholder: 'City',
            options: this.cities$.pipe(
              map((d) => d.map((i) => ({ label: i, value: i })))
            ),
          },
        },
      ],
    },
  ];

  filterModel = {};

  filterForm = new FormGroup({});

  constructor(
    private userService: UserService,
    private locationService: LocationService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.filterModel = { ...this.route.snapshot.queryParams };

    this.users$ = this.route.queryParams.pipe(
      switchMap((v) => {
        const params: Filters = {};

        ['locationCity', 'q', 'page'].forEach((k) => {
          if (k === 'page' && v[k] && +v[k] > 0) {
            params.page = +v[k] - 1;
          } else if (v[k]) {
            params[k] = v[k];
          }
        });
        return this.userService.findAll(params);
      })
    );
  }

  updateFilter(v: Filters) {
    if (!v) {
      return;
    }
    const params: Filters = {};

    ['locationCity', 'q'].forEach((k) => {
      if (v[k]) {
        params[k] = v[k];
      }
    });

    this.router.navigate([], { queryParams: params });
  }

  nextPage(page: number) {
    this.router.navigate([], {
      queryParams: {
        page: page + 1,
      },
      queryParamsHandling: 'merge',
    });
  }
  previousPage(page: number) {
    if (page < 0) {
      return;
    }
    this.router.navigate([], {
      queryParams: {
        page: page + 1,
      },
      queryParamsHandling: 'merge',
    });
  }

  ngOnInit(): void {}
}
